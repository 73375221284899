import React, { useState, useEffect } from 'react'
import { City, toCssPrefix, breakpoints, sendDataToGTM } from '@ecommerce/shared'
import styled, { ThemeProvider } from 'styled-components'
import { PageProps } from 'gatsby'
import { Recipe } from '../../graphql/contentfulTypes'
import Layout from '../../components/Layout'
import useTheme from '../../hooks/useTheme'
import Breadcrumbs from '../../components/Breadcrumbs'
import Seo from '../../components/Seo'
import SectionTitle from '../../components/Moments/SectionTitle'
import RecipeSteps from '../../components/Recipes/RecipeSteps'
import RecipeInfo from '../../components/Recipes/RecipeInfo'
import RelatedProductsList from '../../components/Recipes/RelatedProductsList'
import RecipesList from '../../components/Recipes/RecipesList'
import Video from '../../components/Video'
import ShareModal from '../../components/Recipes/ShareModal'
import { backgroundImageStyles } from '../../utils/styles'
import { FlatLocationProductCategory } from '../../types/PgPages'

const { cssPrefix, toPrefix } = toCssPrefix('RecipeDetail__')

type Props = PageProps<
  {},
  { city: City; data: Recipe; productCategories: FlatLocationProductCategory[] },
  { themeId?: string }
>

const Wrapper = styled.div<{ backgroundImage?: string }>`
  background: ${({ theme }) => theme.colors.bodyBackground.alt};
  color: ${({ theme }) => theme.colors.bodyText};
  ${backgroundImageStyles()}

  .${cssPrefix} {
    &inner {
      max-width: 1360px;
      min-height: 100vh;
      margin: auto;
      position: relative;
      padding-bottom: 50px;
    }

    &top {
      padding-top: 40px;
      position: relative;
      display: none;
    }

    &section-title {
      margin: 0 0 auto;
    }

    &breadcrumbs {
      width: auto;
      padding-left: 0;
      background: inherit;
      margin-bottom: 0;
    }

    &content {
      width: 100%;

      &-left {
        width: 100%;
        height: 300px;
      }

      &-right {
        background: ${({ theme }) => theme.colors.productView.containerBackground || theme.colors.brand.default};
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
        margin-top: 25px;
        padding: 55px 25px;
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    .${cssPrefix} {
      &content {
        &-left {
          height: 340px;
        }

        &-right {
          margin: 24px;
          padding: 40px;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 0 80px;

    .${cssPrefix} {
      &top {
        display: block;
      }

      &breadcrumbs {
        z-index: 1;
        position: relative;
      }

      &content {
        margin-top: 60px;
        display: flex;
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};

        &-left {
          width: 50%;
          align-self: stretch;
          height: auto;
        }

        &-right {
          width: 50%;
          margin: 0;
          box-shadow: none;
          border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
          border-top-right-radius: ${({ theme }) => theme.borderRadius};
        }
      }

      &related-recipes {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`

const RecipeTemplate = ({ pageContext, location }: Props) => {
  const [showShareModal, setShowShareModal] = useState(false)

  const { city, data, productCategories } = pageContext
  const {
    parentMoment,
    metaTitle,
    metaKeywords,
    metaDescription,
    sliderImage,
    detailTitle,
    preparationVideo,
    preparationVideoPoster,
    preparationVideoTitle,
    preparationVideoDescription,
    steps,
    stepsImages,
    aboutTitle,
    aboutText,
    ingredientsTitle,
    ingredients,
    preparationTitle,
    preparationText,
    relatedProductsTitle,
    relatedProducts,
    relatedRecipesTitle,
    relatedRecipes,
    socialMediaShareMessage,
  } = data
  const { mergeThemes, bodyBackgroundImage } = useTheme(parentMoment?.theme?.contentful_id ?? location?.state?.themeId)

  const citySlug = city.slug
  const breadcrumbs = [
    {
      name: 'Inicio',
      url: `/${citySlug}`,
    },
    {
      name: 'Recetas',
    },
    {
      name: detailTitle,
      url: `#`,
    },
  ]

  useEffect(() => {
    sendDataToGTM({ event: 'watchRecipe', pageTitle: detailTitle })
  }, [])
  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout
        navbarTitlte={parentMoment?.navbar?.title}
        navbarType={parentMoment?.navbar?.type}
        title={metaTitle}
        currentCity={city}
        categories={productCategories}
      >
        <Wrapper backgroundImage={bodyBackgroundImage} className={cssPrefix}>
          {showShareModal && (
            <ShareModal
              title="Comparte nuestra receta en:"
              message={socialMediaShareMessage}
              onClose={() => setShowShareModal(false)}
            />
          )}
          <div className={toPrefix('inner')}>
            <Seo keywords={metaKeywords} description={metaDescription ?? aboutText}>
              <meta property="og:type" content="website" />
              <meta name="twitter:card" content="summary" />
              <meta name="og:title" content={metaTitle} />
              <meta name="og:description" content={metaDescription ?? aboutText} />
              <meta name="og:image" content={sliderImage.file.url} />
              <meta name="twitter:image:alt" content={metaTitle} />
              <meta name="og:url" content={location.href} />
            </Seo>
            <div className={toPrefix('top')}>
              <Breadcrumbs
                items={breadcrumbs}
                className={toPrefix('breadcrumbs')}
                themeId={parentMoment?.theme?.contentful_id ?? location?.state?.themeId}
              />
              <SectionTitle className={toPrefix('section-title')} text={detailTitle} />
            </div>
            <div className={toPrefix('content')}>
              <div className={toPrefix('content-left')}>
                {preparationVideo?.file?.url ? (
                  <Video
                    preload="none"
                    title={preparationVideoTitle}
                    description={preparationVideoDescription}
                    poster={preparationVideoPoster?.file?.url}
                    src={preparationVideo.file.url}
                  />
                ) : (
                  <RecipeSteps steps={steps} images={stepsImages} />
                )}
              </div>
              <div className={toPrefix('content-right')}>
                <RecipeInfo
                  onShareClick={() => setShowShareModal(true)}
                  data={{ aboutText, aboutTitle, ingredients, ingredientsTitle, preparationTitle, preparationText }}
                />
              </div>
            </div>
            <RelatedProductsList
              city={city}
              skus={relatedProducts.map(({ sku }) => sku)}
              title={relatedProductsTitle}
              themeId={parentMoment?.theme?.contentful_id}
              listName={metaTitle}
            />
            <RecipesList
              themeId={parentMoment?.theme?.contentful_id ?? location?.state?.themeId}
              className={toPrefix('related-recipes')}
              title={relatedRecipesTitle}
              items={relatedRecipes?.map((item) => ({
                ...item,
                slug: `/${city.slug}/recetas/${item.slug}`,
              }))}
            />
          </div>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  )
}

export default RecipeTemplate
