import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import { ProductCart, toCssPrefix, breakpoints, City, slugify, sendProductImpressionsToGTM } from '@ecommerce/shared'
import styled, { css } from 'styled-components'
import ProductCardSkeleton from '../Skeletons/ProductCardSkeleton'
import SkeletonItem from '../Skeletons/SkeletonItem'
import Slider from '../FramerSlider'
import { getNavigatableProductsBySkus } from '../../utils/algolia'
import secrets from '../../config/secrets'
import { RecipeSubTitle } from './styled'

const { COUNTRY } = secrets

const ProductCard = loadable(() => import('@ecommerce/shared'), {
  ssr: false,
  fallback: <ProductCardSkeleton />,
  resolveComponent: (components) => components.ProductCard,
})

type Props = {
  title: string
  skus: string[]
  city: City
  themeId?: string
  listName?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('RecipeRelatedProducts__')

const GridContainer = styled.div<{ isSingle: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  .${cssPrefix} {
    &grid {
      display: grid;
      grid-template-columns: 370px;
      row-gap: 20px;
    }
  }
  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &grid {
        grid-template-columns: 370px ${({ isSingle }) => (isSingle ? '' : '370px')};
      }
    }
  }
`

const Wrapper = styled.div<{ productsCount: number }>`
  margin: 40px 0;

  .${cssPrefix} {
    &slide {
      margin: 0 20px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      ${({ productsCount }) =>
        productsCount < 3
          ? css`
              &slider-inner {
                justify-content: center;
              }
            `
          : ''}
    }
  }
`

const RelatedProductsList = ({ title, skus, city, themeId, listName }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [products, setProducts] = useState<ProductCart[]>([])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const algoliaProducts = await getNavigatableProductsBySkus(
          slugify(city.commerceLayer.stockLocation.name),
          skus,
          { hitsPerPage: 1000 },
          city.slug,
          `${listName ?? title ?? 'recetas'}`,
          { themeId },
        )

        if (!algoliaProducts || algoliaProducts.length === 0) return setError(true)

        setProducts(algoliaProducts)
        sendProductImpressionsToGTM({ products: algoliaProducts, currencyCode: COUNTRY === 'BO' ? 'BOB' : 'CLP' })
      } catch (e) {
        setError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProducts()
  }, [])

  if (error) return null

  const cards = isLoading ? (
    <>
      {Array(2)
        .fill(0)
        .map((_, i) => (
          <SkeletonItem height={175} width="330px" margin="20px" animate key={i} />
        ))}
    </>
  ) : (
    products.map((product) => (
      <ProductCard
        onNavigate={() => {
          if (product.onNavigate) return product.onNavigate()
          navigate(`/${city.slug}/products/${product.skuCode}`, {
            state: { themeId },
          })
        }}
        key={product.skuCode}
        className={toPrefix('slide')}
        product={product}
        type="small-horizontal"
      />
    ))
  )

  return (
    <Wrapper productsCount={products.length} className={cssPrefix}>
      {isLoading ? (
        <SkeletonItem height={40} width="250px" margin="auto auto 24px" animate />
      ) : (
        <RecipeSubTitle>{title}</RecipeSubTitle>
      )}
      {products.length >= 3 ? (
        <Slider className={toPrefix('slider')} itemsCount={products.length}>
          {cards}
        </Slider>
      ) : (
        <GridContainer isSingle={!isLoading && products.length === 1}>
          <div className={toPrefix('grid')}>{cards}</div>
        </GridContainer>
      )}
    </Wrapper>
  )
}

export default RelatedProductsList
